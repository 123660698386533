import { useState, useEffect } from 'react'
import Head from 'next/head'
// import Script from 'next/script'
// import dynamic from 'next/dynamic'
import { SearchOutlined } from '@ant-design/icons'
import Link from 'next/link'
import styles from '../styles/Home.module.scss'
// import Image from 'next/image'
import Router from 'next/router'
import { Input, Divider, Button, notification } from 'antd'
import { useSelector } from 'react-redux'
// import { Loader } from '@googlemaps/js-api-loader'
import { isOnlineDev } from '@utils'
import useLocation from '@/utils/hooks/useLocation'
// import useMobileDetect from '@/utils/isMobile'

const useMobileDetect = () => {
  if (typeof window === 'undefined') return false
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  function mq(query) {
    return typeof window !== 'undefined' && window.matchMedia(query).matches
  }
  // @ts-ignore
  if (
    'ontouchstart' in window ||
    (window?.DocumentTouch && document instanceof DocumentTouch)
  )
    return true
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('') // include the 'heartz' - https://git.io/vznFH
  return mq(query)
}

// const CodeSampleModal = dynamic(() => import('../components/CodeSampleModal'), {
//   ssr: false
// })
const isDev = process.env.NODE_ENV === 'development'

export default function Start(props) {
  const [form, setForm] = useState({
    location: props.location,
    'age-range': null,
    zone: null
  })
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [locationSlide, setLocationSlide] = useState(false)
  const [history, setHistory] = useState([])
  const [token, setToken] = useState(null)
  const [isMobile, setIsMobile] = useState(false)
  const userInfo = useSelector((state) => state.auth.userInfo)
  const [precisionLat, setPrecisionLat] = useState(null)
  const [precisionLng, setPrecisionLng] = useState(null)
  const [googleService, setGoogleService] = useState(null)
  const [timer, setTimer] = useState(null)
  const [searchList, setSearchList] = useState([])
  const [maxHeight, setMaxHeight] = useState(150)

  const loc = useLocation(true)

  useEffect(() => {
    setToken(localStorage.getItem('TOKEN'))
    setIsMobile(useMobileDetect())
    setMaxHeight(
      document.documentElement.clientHeight - 620 > 150
        ? 150
        : document.documentElement.clientHeight - 620 < 60 &&
          document.documentElement.clientHeight - 620 > 0
        ? 60
        : document.documentElement.clientHeight - 620 < 0
        ? 0
        : document.documentElement.clientHeight - 620
    )
  }, [userInfo])

  useEffect(() => {
    let time = 0
    let timer = setInterval(() => {
      if (
        window &&
        window.google &&
        window.google.maps &&
        window.google.maps.places &&
        window.google.maps.places.AutocompleteService
      ) {
        time = 0
        clearInterval(timer)
        const service = new google.maps.places.AutocompleteService()
        setGoogleService(service)
      } else if (time === 10) {
        clearInterval(timer)
      } else {
        time += 1
      }
    }, 2000)
  }, [])

  useEffect(() => {
    const his = localStorage.getItem('location-history')
    try {
      const arr = JSON.parse(his)
      if (Array.isArray(arr)) {
        setHistory(arr)
      }
    } catch (error) {}
  }, [])

  const onSearch = () => {
    const query = {}
    for (const key in form) {
      if (Object.hasOwnProperty.call(form, key)) {
        if (form[key]) {
          query[key] = form[key]
          if (key === 'zone') {
            query.ll = `${longitude},${latitude}`
          }
          if (key === 'location' && form.location !== 'Current Location') {
            const his = history.filter((_) => _ !== form[key])
            his.unshift(form[key])
            localStorage.setItem('location-history', JSON.stringify(his))
            setTimeout(() => {
              setHistory(his)
            }, 200)
          } else if (
            key === 'location' &&
            form.location === 'Current Location' &&
            precisionLat &&
            precisionLng
          ) {
            query.ll = `${precisionLng},${precisionLat}`
          }
        }
      }
    }
    Router.push({ pathname: '/schools', query })
  }

  const getCurrentLocation = () => {
    return new Promise((res, rej) => {
      if (!precisionLat || !precisionLng) {
        if (navigator.geolocation) {
          // 支持geolocation
          navigator.geolocation.getCurrentPosition(function (position) {
            console.log(position)
            // Get the coordinates of the current position.
            var lat = position.coords.latitude
            var lng = position.coords.longitude
            setPrecisionLat(lat)
            setPrecisionLng(lng)
            res({ lat, lng })
          })
        } else {
          rej(false)
        }
      } else {
        res({ lat: precisionLat, lng: precisionLng })
      }
    })
  }

  const selectCurrentLocation = async (e) => {
    // await getCurrentLocation()
    console.log(loc)
    if (loc && loc[0] && loc[0].lat) {
      setPrecisionLat(loc[0].lat)
      setPrecisionLng(loc[0].lng)
      setForm({ ...form, location: 'Current Location' })
    } else {
      notification.error({ message: 'Unable to get your exact location' })
    }
  }

  const selectCurrentHistory = (text) => {
    setForm({ ...form, location: text })
  }

  const onBlurLocation = () => {
    setTimeout(() => {
      setLocationSlide(false)
    }, 200)
  }

  const onInputAddress = async (e) => {
    const address = e.target.value
    setForm({ ...form, location: address })
    const { lat, lng } = await getCurrentLocation()
    if (googleService) {
      clearTimeout(timer)
      let t = setTimeout(() => {
        if (address) {
          googleService.getQueryPredictions(
            {
              input: address,
              radius: 100,
              location: new google.maps.LatLng(lat, lng)
            },
            function (results) {
              setSearchList(results.map((_) => _.description))
            }
          )
        }
      }, 1000)
      setTimer(t)
    }
  }

  return (
    <div>
      <Head>
        <title>
          Y Montessori - Where Montessori Schools, Parents, and Data Meet.
        </title>
        <meta
          name='title'
          content='Find Montessori schools, foster seamless parent-educator connections, and access data-driven insights—all with Y Montessori.'
        />
		<link rel='icon' href='/favicon.ico' />
        <link
          href='https://fonts.googleapis.com/css2?family=Inter'
          rel='stylesheet'
        />
      </Head>

      <main className={styles.container}>
        <iframe
          className={styles.iframe}
          frameborder='0'
          src='https://isabellacai2.wixstudio.com/montopiapage'
        ></iframe>
      </main>
    </div>
  )
}

export async function getServerSideProps(context) {
  const ip = context.req.headers['x-real-ip'] || '104.177.116.181'
  let location = null
  try {
    const latlng1 = await fetch(
      `${process.env.NEXT_PUBLIC_API}/api/tool/location?ip=${ip}`
    )
    const latlng2 = await latlng1.json()
    const latlng = latlng2.data || {}
    if (latlng && latlng.status === 'success') {
      location = `${latlng.city}, ${latlng.region}`
    }
  } catch (error) {
    console.log(error)
  }

  return {
    props: {
      location
    }
  }
}
